// ============================
//   4. header css start
// ============================

.pc-header {
  background: var(--pc-header-background);
  color: var(--pc-header-color);
  min-height: $header-height;
  box-shadow: var(--pc-header-shadow);
  backdrop-filter: blur(7px);
  position: fixed;
  left: $sidebar-width;
  right: 0;
  z-index: 1025;
  display: flex;

  ul {
    margin-bottom: 0;
    display: inline-flex;
  }

  .m-header {
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: $sidebar-width;
    padding: 16px 10px 16px 24px;
  }

  .header-wrapper {
    display: flex;
    padding: 0 25px;
    flex-grow: 1;
    @include media-breakpoint-down(sm) { 
      padding: 0 15px;
    }
  }

  .header-search {
    position: relative;

    .form-control {
      border-radius: $border-radius;
      padding: 0.344rem 1.8rem;
      width: 200px;
      max-width: 100%;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }

    .icon-search {
      position: absolute;
      top: 11px;
      left: 11px;
      width: 12px;
      height: 12px;
    }

    .btn-search {
      position: absolute;
      top: 7px;
      right: 9px;
      padding: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      border-radius: $border-radius;
    }
  }

  .pc-h-item {
    min-height: $header-height;
    display: flex;
    align-items: center;
    position: relative;
  }

  .pc-head-link {
    margin: 0 4px;
    position: relative;
    font-weight: 500;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: $border-radius;
    color: var(--pc-header-color);
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: var(--pc-active-background);
      border-radius: 50%;
      transform: scale(0);
      transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
    }

    >svg,
    >i {
      position: relative;
      z-index: 5;
      color: var(--pc-header-color);
      transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
    }

    >i {
      font-size: 24px;
    }

    >svg {
      width: 24px;
      height: 24px;
    }

    &.active,
    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
      color: var(--pc-header-color);

      >svg,
      >i {
        color: var(--pc-header-color);
        transform: scale(1.08);
      }

      &::before {
        border-radius: 0;
        transform: scale(1);
      }

      .hamburger {
        .hamburger-inner {
          background-color: $secondary;

          &::after,
          &::before {
            background-color: $secondary;
          }
        }
      }

      i.material-icons-two-tone {
        background-color: $secondary;
      }
    }

    .pc-h-badge {
      position: absolute;
      top: 2px;
      right: 2px;
      border-radius: 50%;
      font-size: 9px;
      z-index: 9;

      &.dots {
        width: 9px;
        height: 9px;
        top: 7px;
        right: 16px;
        padding: 0;
      }
    }

    .user-desc,
    .user-name {
      display: block;
      line-height: 1;
    }

    .user-name {
      margin-bottom: 5px;

      font: {
        size: 15px;
        weight: 600;
      }
    }

    .user-desc {
      font: {
        size: 12px;
        weight: 400;
      }

      color: var(--pc-header-color);
    }
  }

  .pc-h-dropdown {
    .ti-circle {
      font-size: 7px;
      vertical-align: middle;
    }

    transform: none !important;
    top: 100% !important;

    &.dropdown-menu-end {
      right: 0 !important;
      left: auto !important;
    }
  }

  .pc-level-menu {
    position: relative;

    &:hover {
      >.dropdown-menu {
        display: block;
        left: 100%;
        top: -18px !important;
      }
    }
  }

  .drp-search {
    min-width: 20rem;
  }

  #vertical-nav-toggle {
    margin-left: -15px;
  }

  .pc-cart-menu .drp-cart {
    min-width: 20rem;
    padding: 0;

    .cart-head {
      border-radius: 2px 2px 0 0;
      padding: 20px;
      background: transparentize(shift-color($primary, $soft-bg-level), 0.8);
      border-bottom: 1px solid $border-color;
    }

    .cart-item {
      display: flex;
      padding: 10px 20px;

      + .cart-item {
        border-top: 1px dashed $border-color;
      }

      img {
        width: 60px;
      }

      .cart-desc {
        flex: 1;
      }

      .p-star {
        fill: currentColor;
      }

      &.table-responsive {
        border-top: 1px solid $border-color;
        background: transparentize(shift-color($primary, $soft-bg-level), 0.8);
      }

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  .user-avtar {
    width: 40px;
    border-radius: 50%;
  }

  .header-user-profile {
    .pc-head-link {
      width: auto;
      padding: 7px;
      background: transparent;

      &:focus,
      &:hover {
        .user-avtar {
          transform: scale(1.03);
        }

        &::before {
          display: none;
        }
      }

      >span>i {
        font-size: 22px;
        margin-right: 8px;
      }

      .user-avtar {
        width: 40px;
        transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
      }

      @include media-breakpoint-down(sm) {
        width: 40px;

        .user-avtar {
          margin-right: 0;
        }

        >span,
        >span>i {
          display: none;
        }
      }
    }
  }

  .dropdown-user-profile {
    min-width: 352px;
    max-width: 100%;
    .dropdown-header,
    .dropdown-body {
      padding: 16px 20px;
    }
    .dropdown-item {
      padding: 10px 15px;
      display: flex;
      border-radius: var(--bs-border-radius);
      align-items: center;
      justify-content: space-between;
      border: var(--bs-border-width) var(--bs-border-style) transparent;
      &:hover,
      &:focus {
        color: var(--bs-dropdown-link-color);
        background: var(--pc-active-background);
        border-color: var(--bs-border-color);
      }

      .user-group {

        .avtar,
        img {
          width: 30px;
          height: 30px;
          font-size: 14px;
        }
      }
    }
    @media (max-width: 575.98px) {
      min-width: calc(100% - 30px);
    }
  }

  .dropdown-notification {
    min-width: 450px;
    max-width: 100%;
    .dropdown-header,
    .dropdown-body {
      padding: 16px 20px;
    }
    .list-group-item-action {

      &:active,
      &:hover,
      &:focus {
        background: shift-color($primary, $soft-bg-level);
      }

      .user-avtar,
      h5 {
        cursor: pointer;
      }
    }

    .badge {
      font-size: 0.8125rem;
      padding: 0.43em 1em;
    }

    .user-avtar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      font-size: 20px;
    }

    .notification-file {
      display: flex;
      align-items: center;

      i {
        font-size: 20px;
        margin-right: 16px;
      }
    }

    .card {
      cursor: pointer;

      &:hover {
        background: var(--pc-active-background);
      }
    }

    @media (max-width: 575.98px) {
      min-width: calc(100% - 30px);
    }
  }
}
.pc-announcement-offcanvas {
  --bs-offcanvas-width: 474px;
  .offcanvas-body {
    &::-webkit-scrollbar {
      width: 6px;
      opacity: 0;
      &:hover {
        opacity: 1;
      }
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: darken($body-bg, 05%);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: darken($body-bg, 25%);
    }
  }
}
.drp-upgrade-card {
  background-size: cover;

  .user-group {

    .avtar,
    img {
      font-size: 14px;
    }
  }
}

.user-group {
  display: inline-flex;
  align-items: center;

  .avtar,
  img {
    cursor: pointer;
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 2;
    transition: all 0.1s ease-in-out;
    border: 2px solid $card-bg;

    +.avtar,
    +img {
      margin-left: -14px;
    }

    &:hover {
      z-index: 5;
    }
  }
}

.notification-modal.modal.fade .modal-dialog {
  transform: translate(100%, 0);
}

.notification-modal.modal.show .modal-dialog {
  transform: none;
}

.notification-modal {
  padding-right: 0 !important;

  .modal-dialog {
    margin: 0 0 0 auto;
  }

  .modal-content {
    min-height: 100vh;
    border: none;
    border-radius: 0;
    box-shadow: -1px 0 9px -3px rgba(0, 0, 0, 0.6);
  }
}

@media (max-width: 1024px) {
  .pc-header {
    top: 0;
    left: 0;
    transition: all 0.15s ease-in-out;

    .m-header {
      display: none;
    }

    .pc-head-link {

      .user-desc,
      .user-name {
        display: none;
      }
    }

    .pcm-logo {
      margin-left: 30px;

      img {
        max-width: 160px;
      }
    }

    &.mob-header-active {
      top: $header-height;

      .header-wrapper {
        background: var(pc-header-background);
        position: relative;
        z-index: 5;
      }
    }

    .pc-md-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 1;
      background: rgba(0, 0, 0, 0.2);
    }

    .pc-level-menu .dropdown-menu {
      left: 0;
      display: block;
      padding-left: 30px;
    }

    .pc-mob-drp {
      &.mob-drp-active {
        .pc-h-item {
          display: block;
          min-height: auto;
          position: relative;

          .pc-head-link {
            display: block;
            margin: 5px 10px !important;
          }

          .dropdown-menu {
            position: relative !important;
            width: 100%;
            float: none;
            box-shadow: none;
          }
        }

        ul {
          display: block;
        }
      }
    }
  }

  .minimenu {
    .pc-header {
      left: 0;
    }
  }
}

#pc-noti-home {
  >.media {
    &:hover {
      margin: -15px;
      padding: 15px;
      background: rgba($primary, 0.1);
    }
  }
}

@include media-breakpoint-down(sm) {
  .pc-header {
    .pc-head-link {
      padding: 0.65rem;
      margin: 0 5px;
    }

    .pc-h-item {
      position: static;

      .pc-h-dropdown {
        left: 15px !important;
        right: 15px !important;
      }
    }
  }
}

// ============================
//   4. header css end
// ============================